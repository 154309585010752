//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿
// TYPOGRAPHY
//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  letter-spacing: -0.18px;
  line-height: 20px;
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
  margin-bottom: 10px;
  color: $navy;
}

.caption {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 20px;
}

.label {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  line-height: 12px;
}

.display-xl {
  font-family: "Raleway Bold", sans-serif;
  font-size: 60px;
  line-height: 72px;
}

.display-l {
  font-family: "Raleway Semibold", sans-serif;
  font-size: 48px;
  line-height: 58px;
}

.display-m {
  font-family: "Raleway Semibold", sans-serif;
  font-size: 28px;
  line-height: 60px;
}

.display-sm {
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  line-height: 28px;
}

.page-heading {
  font-family: "Open Sans Semibold", sans-serif;
  font-size: 24px;
  line-height: 34px;
}

.heading {
  font-family: "Open Sans Semibold", sans-serif;
  font-size: 20px;
  line-height: 28px;
}

.section-heading {
  font-family: "Open Sans Semibold", sans-serif;
  font-size: 18px;
  line-height: 26px;
}

.subheading {
  font-family: "Open Sans Semibold", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}

a {
  text-decoration: none;
}

@mixin labelStyle {
  font-size: 10px;
  line-height: 12px;
}

@mixin captionStyle {
  font-size: 12px;
  line-height: 20px;
}

@mixin pageHeadingStyle {
  font-family: "OpenSans-Semibold", sans-serif;
  font-size: 24px;
  line-height: 34px;
}

@mixin headingStyle {
  font-family: "OpenSans-Semibold", sans-serif;
  font-size: 20px;
  line-height: 28px;
}

@mixin sectionHeadingStyle {
  font-family: "OpenSans-Semibold", sans-serif;
  font-size: 18px;
  line-height: 26px;
}

@mixin subheadingStyle {
  font-family: "OpenSans-Semibold", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}