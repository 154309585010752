
//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿
// TYPOGRAPHY
//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans Light';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/open-sans-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/open-sans-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/open-sans-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/open-sans-v18-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/open-sans-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/open-sans-v18-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/open-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/open-sans-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/open-sans-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/open-sans-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/open-sans-v18-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans Semibold';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/open-sans-v18-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/open-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/open-sans-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/open-sans-v18-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./fonts/open-sans-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/open-sans-v18-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/open-sans-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/open-sans-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/open-sans-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/open-sans-v18-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/open-sans-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/open-sans-v18-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* raleway-300 - latin */
@font-face {
  font-family: 'Raleway Light';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/raleway-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/raleway-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/raleway-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/raleway-v18-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/raleway-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/raleway-v18-latin-300.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/raleway-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/raleway-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/raleway-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/raleway-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/raleway-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/raleway-v18-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* raleway-600 - latin */
@font-face {
  font-family: 'Raleway Semibold';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/raleway-v18-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/raleway-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/raleway-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/raleway-v18-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./fonts/raleway-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/raleway-v18-latin-600.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/raleway-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/raleway-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/raleway-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/raleway-v18-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/raleway-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/raleway-v18-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
}

