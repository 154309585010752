//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿
// CART
//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿

.cart {
    width: 95%;
    margin: auto;
    background-color: $bg;

    &__container {
        width: 100%;
    }

    &__table {
        margin-top: 15px;
        background-color: #FFFFFF;
        padding:10px;
        border-radius: 5px;

        h2 {
            margin-bottom: 15px;
        }

        .head, form {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-start;
        }

        .head {
            font-weight: 600;
            border-bottom: solid 1px $navy10;
        }

        form {
            border-bottom: solid 1px $navy10;
        }

        .cell {
            padding: 20px 5px;
            min-width: 50px;

            .hidden {
                height: 0; width: 0;
            }

            &.product, &.remove { 
                width: 100px;

                input {text-transform: capitalize;}
            }

            &.type {
                input {text-transform: uppercase;}
            }

            &.date, &.time, &.location {
                min-width: 22%;

                input { overflow-x: scroll }
            }

            .days {
                color: $navy70;
                font-size: 12px;
            }

            &.state, &.type {
                width: 67px;
            }

            input {
                padding: 0;
                border: 0;
                background: none!important;
                height: 25px;
            }
        }

        .empty {
            font-size: 30px;
            padding: 50px;
            text-align: center;
        }

        .remove {
            color: $dark-red;
            cursor: pointer;
        }
    }

    &__buttons {
        margin: 20px 0;
        display: inline-block;
        width: 100%;

        button {
            float: right;
            margin-left: 10px;
            transition: 0.4s;

            &.active {
                background-color: $dark-green;
            }
        }
    }
}