
//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿
// COLORS
//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿

$white : #FFFFFF;
$beige: #F6F5ee;

$navy: #1C2F5E;
$navy70: #515E80;
$navy40: #A4ACBF;
$navy20: #D2D5DF;
$navy10: #E8EAEE;
$bg: #F4F5FA;


$cornflower: #77AEFF;
$light-cornflower: #C5DDFF;
$dark-cornflower: #536DAE;

$mint: #83E0CA;
$light-mint: #ADFFEB;
$dark-mint: #3F817F;

$dodger-blue: #426FE1;
$light-dodger-blue:  #B7CCFF;
$dark-dodger-blue: #365CBA;

$ocean: #3CADD0;
$light-ocean: #9EE2F7;
$dark-ocean: #297b95;

$tangerine: #f2B25C;
$light-tangerine: #FFE2BC;
$dark-tangerine: #BC8740;

$rose: #FF4181;
$light-rose: #FFB7CF;
$dark-rose: #D12368;

$smoke: #8A8F9B;
$light-smoke: #CFD0D3;
$dark-smoke: #262626;

$orchid: #B96BEF;
$light-orchid: #e3BAFF;
$dark-orchid: #9B57C9;

$dark-red: #BF1722;
$red: #F03E3E;
$light-red: #FBEAE5;

$dark-yellow: #8F6214;
$yellow: #F3CF30;
$light-yellow: #FCF4D9;

$dark-green: #168368;
$green: #25CEA3;
$light-green: #DFFDEC;