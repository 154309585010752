//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿
// FIELDS
//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿

input[type="text"],
input[type="textarea"],
.dropdown {
  @include size(100%, 44px);
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $navy20;
  padding: 10px;
  color: $navy;
  transition: 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::placeholder {
    color: rgba($navy, 0.5);
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 4px 0 rgba($navy, 0.4);
  }

  &.time {
    background-color: $white;
    width: 100px;
    height: auto;
    border: 0;
    margin-bottom: 10px;

    &:nth-of-type(2) {
      margin-left: 10px;
    }
  }
}

/* search */
.search {
  padding-left: 28px !important;
  padding-right: 28px!important;
  background: url("../../assets/images/icn-magnifying-glass.svg") no-repeat left 7px center $white;
  transition: 0.3s;
  position: relative;
  width: calc(100% - 65px)!important;
  float: left;
  border-left: 0!important;
  border-radius: 0px 4px 4px 0px!important;

  &:focus,
  &:active {
    background-image: url("../../assets/images/icn-magnifying-glass-active.svg");
  }
}

.clear-icon {
  @include absolute(auto, 0px, auto, auto);
  cursor: pointer;
  margin-top: 2px;
}

.suggestions {
  max-width: 100%;
  background-color: $white;
  position: absolute;
  margin-top: 75px;
  z-index: 10;
  padding: 0;

  li {
    width: 100%;
    padding: 15px 15px 15px 25px;
    border-bottom: solid 1px $bg;
    cursor: pointer;
    list-style-type: none;
    text-align: left;
    display: inline-block;
    background: url("../../assets/images/icn-pin.svg") no-repeat left 10px top 20px;
    background-size: 8px;
    text-transform: capitalize;

    &:hover {
      background-color: $navy20;
    }

    &:last-of-type {
      border: 0;
    }
  }
}

/* dropdpown */
.dropdown {
  background: url("../../assets/images/dropdown-arrow.svg") no-repeat right 10px
    center $white;
  background-size: 10px auto;
  padding-right: 20px;
  cursor: pointer;
  transition: 0s;

  &.open {
    background-image: url("../../assets/images/icn-arrow-right.svg");
    background-position: right -5px center;
    background-size: 40px;
  }

  &.with-button {
    width: 93%;
    transition: width 0.3s;
  }

  &.date-range {
    padding-left: 10px;
    position: relative;

    span {
      position: relative;
      background: url("../../assets/images/icn-calendar.svg") no-repeat left -10px center $white;
      padding-left: 40px;

      &:before {
        @include size(1px, 100%);
        position: absolute;
        left: 30px;
        content: "";
        background-color: $bg;
      }
    }
  }

  &.state {
    width: 65px;
    clear: both;
    float: left;
    border-right: 0!important;
    border-radius: 4px 0 0 4px;
    box-shadow: inset -1px 0 0 0 $navy20;
  }
}
