//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿
// MAP
//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿

.map {
  @include size(100%, 100vh);
  position: relative;
  cursor: pointer;
  z-index: 0;
}

.ol-map {
  @include size(100%);
  position: relative;

  &.hide {
    position: absolute;
    opacity: 0;
  }
}

.ol-map-tooltip {
  position: absolute;
  z-index: 1;
  width: 170px;
  background-color: $navy;
  opacity: 0.9;
  display: none;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  overflow-wrap: break-word;
  margin-left: -20px;
}

.ol-map-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: $navy transparent transparent transparent;
}

.ol-control {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
}
.ol-full-screen {
  top: 0.5em;
  right: 0.5em;
}

.absolute {
  position: absolute;
  top: 0;
  left: 0;
}

.ol-control {
  display: flex;
  flex-direction: column;
  margin: 5px 0px 0px 5px;
  background-color: #ffffff;
  border-radius: 5.38px;
  box-shadow: 0 0 8px 0 rgba(28, 47, 94, 0.2);
  width: 27px;
  height: auto;
}

.ol-control button {
  min-width: 0px;
  background: none;
  color: $dodger-blue;
  margin-left: -8px;
  font-size: 17px;
}

.ol-zoom-extent {
  top: 85px;
}
.ol-zoom-extent button {
  margin-left: -12px;
}

.ol-rotate {
  display: none;
}

.ol-attribution {
  display: none;
}

.ol-active {
  display: block;
}

.ol-overlaycontainer-stopevent {
  z-index: 2!important;
}