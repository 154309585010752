//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿
// DATE RANGE EXTENSION
//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿

.date-range-panel {
  @include absolute(0, auto, auto, 370px);
  background-color: $bg;
  border-radius: 6px;
  box-shadow: 0 0 9px 0 rgba($navy, 0.45);
  width: 350px;
  z-index: 10;

  section {
    margin-bottom: 25px;
    width: 100%;
    display: inline-block;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  .header, .footer {
    padding: 15px 20px;
    position: relative;
    width: 100%;

    .title, .section-heading { margin-bottom: 0;}
  }

  .header {
    border-bottom: 1px solid $navy20;
  }

  .footer {
    border-top: 1px solid $navy20;
    display: inline-block;
  
    button {
      float: left;
      padding: 0 20px;
      
      &:last-of-type {
        float: right;
      }
    }
  }

  .content {
    width: 100%;
    padding: 20px;
    display: inline-block;
  }

  .time-container {
    position: relative;
    display: inline-block;
    min-height: 50px;

    &:first-of-type {
      margin-right: 30px;
      &:after {
        content: " - ";
        position: absolute;
        top: 10px;
        margin-left: 13px;
      }
    }

    .time-list {
      position: absolute;
      list-style: none;
      padding: 20px 5px;
      background-color: $bg;
      z-index: 20;
      max-height: 300px;
      overflow: scroll;
      margin-top: -15px;

      li {
        padding: 5px 20px;

        &:hover {
          background: $bg;
          cursor: pointer;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }

        &.active {
          font-weight: bold;
        }
      }
    }
  }
}

.date-range-wrapper {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;

  .close {
    right: 0px;
    top: 14px;
  }

  .swap {
    @include absolute (5px, -15px, auto, auto);
    cursor: pointer;
  }
}

.date-range {
  .half {
    width: 48%;
  }

  &__day {
    @include size(42px, 34px);
    float: left;
    text-align: center;
    padding: 7px;
    color: $navy70;
    background: white;
    margin-right: 2px;
    cursor: pointer;

    &:first-child {
      border-radius: 2px 0 0 2px;
    }

    &:last-of-type {
      border-radius: 0 2px 2px 0;
    }

    &.active {
      background-color: $dodger-blue;
      color: white;
    }
  }

  &__time {
    display: flex;
  }
}