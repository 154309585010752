.progress-bar {
  &__container {
    @include size(100%, 5px);
    margin: 0 auto;
    position:relative;
    background-color: $navy20;
    padding: 1px;

    &:before {
      content:'';
      position:absolute;
      top:-4px; 
      right:-4px; 
      bottom:-4px; 
      left:-4px;
    }
  }
  &__bar { 
    position:absolute;
    top:0;
    right:100%;
    bottom:0;
    left:0;
    background: $green;
    width:0;
    animation: animationBar 2s linear infinite;

    &.error {
      background: $red;
    }
  }
    
  @keyframes animationBar {
    0% {
      left:0%;
      right:100%;
      width:0%;
    }
    10% {
      left:0%;
      right:75%;
      width:25%;
    }
    90% {
      right:0%;
      left:75%;
      width:25%;
    }
    100% {
      left:100%;
      right:0%;
      width:0%;
    }
  }

  p {
    color: #1c2f5e;
    font-family: 'Open Sans', sans-serif;
  }

  .main-text {
    font-size: 12px;
    line-height: 14px;
    margin-top: 25px;
  }

  .sub-text {
    font-size: 10px;
    line-height: 12px;
    margin-top: 12px;
  }
}
