//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿
// POWERBI
//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿

.powerbi-main {
  @include absolute(15px, 30px, auto, auto);
  @include size(450px, calc(100% - 30px));
  box-shadow: 0 0 9px 0 rgba(28, 47, 94, 0.45);
  background-color: $bg;
  border-radius: 6px;
  padding: 0px;
  z-index: 8;
  transition: 0.3s !important;
  display: block;
  max-height: 880px;
  overflow: hidden;

  .pbi-report-container {
    height: calc(100% - 40px);

    &.with-notif {
      height: calc(100% - 100px);
    }
  }

  #report-iframe-container {
    @include size(100%);
    overflow: auto;

    iframe {
      border: none;
      max-height: 98%;
    }
  }

  &.expanded, &.withCart {
    @include absolute(5px, 5px, 5px, auto);
    width: calc(100% - 450px);
    height: 100%;
    max-height: calc(100% - 10px);
    display: block;
  }

  .help-menu {
    top: 40px; 
    right: 65px;
  }

  .header {
    @include size(100%, 50px);
    border-bottom: 1px solid $navy20;
    padding: 12px 15px 0;
    position: relative;
    display: inline-block;
    z-index: 15;

    &.move-granularity {
      height: 95px;
      border: 0;

      .tabs {
        position: absolute;
        width: 100%;
        top: 41px;
        left: 0px;
        border-top: 1px solid $navy20;
        margin-top: 7px;
      }
    }

    img {
      width: 12px;
      cursor: pointer;
    }

    .download {
      margin-left: 7px;
    }

    .separator {
      @include size(1px, 20px);
      background-color: $navy20;
    }

    .section-heading {
      text-transform: capitalize;
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      padding-top: 4px;
    }

    &__buttons {
      margin-right: 10px;
      margin-top: -4px;
      min-width: 278px;
      text-align: right;

      .button {
        &--subtle { 
          background-color: $navy10;
          border-color: $navy10;
          border-radius: 0;

          &:disabled {
            pointer-events: all;
            opacity: 1;
            color: $navy40;

            &:hover {
              border-color: transparent;
              cursor: default;

              + .tooltip {
                display: block;
              }
            }
          }

          &:nth-of-type(1) {
            border-radius: 6px 0px 0px 6px;
          }

          &.data {
            border-radius: 0 6px 6px 0;
          }
        }

        &--subtle, &--reversed {
          font-size: 12px;
          padding: 0 10px;
        }

        &--reversed {
          box-shadow: 0 0 6px 0 $navy20;
        }
      }

      .updated {
        @include absolute(0px, 110px, auto, auto);
        @include labelStyle;
        color: white;
        background-color: $dodger-blue;
        border-radius: 20px;
        padding: 2px 5px;
        width: auto;
        z-index: 2;
      }

      .tooltip {
        right: 80px;
        display: none;
        text-align: center;
      }
    }

    &__open {
      display: none;
    }

    .close {
      position: relative;
      top: -2px;
      right: 0;
    }
  }
}

.raw-data-info {
  @include absolute(auto, 0, 20px, 0);
  width: 100%;
  text-align: center;
}

.powerbi-empty {
  @include size(100%, calc(100% - 50px));
  @include captionStyle;
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    text-align: center;
    padding: 5px;
  }

  img {
    margin-bottom: 5px;
  }
}

.progress-bar {
  position: absolute;
  top: 311px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;

  &__wrapper {
    width: 303px;
  }
}

.powerbi-micro {
  position: absolute;
  right: 0;
  background: $bg;
  height: 100vh;
  width: 62px;
  box-shadow: 0 0 9px 0 rgba(28, 47, 94, 0.45);

  .pbi-maximize {
    margin: 25px 16px;
    width: 30px;
    cursor: pointer;
  }
}
