//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿
// USER INPUT PANEL
//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿

.user-input-panel {
  @include size(370px, 100vh);
  float: left;
  background-color: $bg;
  box-shadow: 0 0 9px 0 rgba($navy, 0.45);
  z-index: 10;
  position: relative;
  transition: 0.3s;
  min-width: 370px;

  .button--text,
  h1 {
    margin-bottom: 7px;
  }

  h1 {
    margin-top: 2px;
  }

  /******* HEADER *******/
  .header {
    border-bottom: 1px solid rgba($navy, 0.2);
    padding: 15px 0;
    position: relative;
    width: 100%;
    z-index: 15;

    * {
      transition: 0.3s;
      transition-delay: 0.3s;
    }

    &__logo {
      padding-left: 15px;
      width: 110px;
    }

    &__question {
      @include absolute(13px, 10px, auto, auto);
      @include size(28px);
      cursor: pointer;
    }

    .subscription-tag {
      @include absolute(18px, auto, auto, 115px);
    }

    &__open {
      display: none;
    }

    &__upgrade {
      @include absolute(17px, auto, auto, 170px);
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  /******* HEADER FOR INPUT FIELD *******/
  .header-field {
    display: flex;
    justify-content: space-between;

    .btn-reset {
      cursor: pointer;
      margin-right: 15px;

      span {
        color: $navy70;
        letter-spacing: 0.49px;
        line-height: 16px;
      }

      .icn-reset {
        height: 26px;
      }
    }

    &.btn-reset-all > div {
      background-color: rgba(28, 47, 94, 0.1);
      border-radius: 3px;
      width: 91px;
      height: 27px;
      margin: -7px 1px 7px 0px;
    }
  }

  &__container {
    padding: 10px 15px;
    transition: 0.3s;
    transition-delay: 0.3s;

    .main-section {
      position: relative;

      &:not(:first-of-type) {
        margin-top: 30px;
      }

      &.location {
        z-index: 10;
      }

      &.date-range {
        z-index: 0;
      }

      .selected-items__wrapper {
        max-height: 150px;
        overflow-y: auto;
      }
    }
  }

  /******* LOCATION *******/
  .location-container {
    padding: 15px;
    z-index: 10;
    position: relative;
    transition-delay: 0.3s;
    display: inline-block;
    width: 100%;

    .location {
      position: relative;

      .clear-icon {
        top: 30px;
      }
    }
  }

  /***** DATE RANGE ******/
  .clock {
    color: $navy70;
    padding: 3px 0 7px 15px;
    background: url('../../assets/images/icn-time.svg') no-repeat left top 7px;
  }

  /******* DISPLAY AS *******/
  .display-boxes-container {
    display: inline-block;

    &.disabled {
      cursor: not-allowed;
      
      * {
        pointer-events: none;
        opacity: 0.8;
        background-color: transparent!important;
      }
    }

    .display-box-wrapper {
      &:nth-of-type(3) .display-box {
        margin-right: 0;
      }

      &:nth-of-type(1) .display-sub,
      &:nth-of-type(2) .display-sub {
        border-bottom: solid 1px $navy40;
      }
    }

    .display-box {
      @include size(70px);
      border: solid 1px $navy20;
      border-radius: 3px;
      float: left;
      padding: 10px 5px 5px;
      text-align: center;
      margin: 8px 15px 8px 0;
      color: $navy70;
      font-size: 10px;
      cursor: pointer;

      img {
        max-width: 30px;
      }

      &.active {
        background-color: rgba($light-cornflower, 0.5);
        border-color: $light-cornflower;
      }

      &:nth-of-type(5) {
        margin-right: 0;
      }
    }

    .display-sub {
      background-color: $bg;
      width: 100%;
      padding: 10px;
      display: flex;
      flex-wrap: wrap;

      .checkbox-container {
        @include labelStyle;
        min-width: calc(50% - 6px);
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0 3px;

        &:nth-of-type(1),
        &:nth-of-type(2) {
          border-bottom: solid 1px $navy40;
        }

        .checkmark {
          top: 8px;
        }
      }
    }
  }

  /******* DIRECTION *******/
  .direction {
    display: inline-block;
    width: 100%;

    &__origin,
    &__destination {
      @include size(50%, 40px);
      float: left;
      border: 2px solid #eaeef1;
      padding: 8px;
      text-align: center;
      cursor: pointer;

      img {
        margin-right: 5px;
      }

      &.active {
        background-color: $navy20;
      }
    }

    &__origin {
      border-radius: 2px 0 0 2px;
      border-right: 0;
    }

    &__destination {
      border-radius: 0px 2px 2px 0;
    }
  }
}
