//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿
// BUTTONS
//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿

button {
  color: $white;
  background-color: $dodger-blue;
  font-size: 14px;
  letter-spacing: 0.45px;
  width: auto;
  padding: 0px 15px;
  text-align: center;
  border: 0;
  border-radius: 5px;
  height: 28px;
  outline: 0;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.2s;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.button--outlined {
    background-color: transparent;
    border: solid 1px $dodger-blue;
    color: $navy;

    &:hover {
      border: solid 1px transparent;
    }
  }

  &.button--subtle {
    background-color: $white;
    border: 1px solid #eaeef1;
    color: $navy70;

    &:hover {
      background-color: $white;
      border: 1px solid $navy70;
      color: $navy70;
    }
  }

  &.button--text {
    background-color: transparent;
    border: none;
    color: $dodger-blue;
    padding: 0;
    min-width: 0;

    &:hover {
      background-color: transparent;
      color: $cornflower;
    }
  }

  &.button--reversed {
    background-color: $white;
    color: $dodger-blue;

    &:disabled {
      opacity: 1;
      color: $navy40;
      border: 1px solid #eaeef1;
      background-color: $navy10;
    }
  }

  &.button--reversed-outlined {
    background-color: transparent;
    color: $white;
    border: solid 1px $white;

    &:hover {
      border: solid 1px transparent;
    }
  }

  &.button--medium {
    height: 37px;
    font-size: 15px;
    letter-spacing: 0.6px;
    min-width: 115px;
  }

  &.button--large {
    height: 46px;
    font-size: 18px;
    letter-spacing: 0.7px;
    min-width: 140px;
  }

  &.button--with-icon {
    padding-left: 40px;
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: top 45% left 15%;
  }

  &:hover {
    background-color: $navy;
    color: $white;
    border: 0;
  }
}
