//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿
// DATE PICKER
//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿

.ant-picker {
  @include size(100%, 40px);
  border: solid 1px $bg !important;
  border-radius: 5px;
  background-color: $white;
  font-size: 12px;
  padding: 0 15px;
  position: relative;
  overflow: hidden;

  &.ant-picker-focused {
    box-shadow: 0 0 2px rgba($dodger-blue, 0.2) !important;
  }

  .ant-picker-active-bar {
    background: $dodger-blue !important;
  }

  input {
    color: $navy;
  }
}

.ant-picker-header button {
  min-width: 20px;
  line-height: 10px;
}

.ant-picker-header > button {
  margin: 10px 6px;
}

.ant-picker-header-view {
  padding-top: 5px;

  button {
    color: $navy;

    &:hover {
      background-color: transparent;
      color: $dodger-blue;
    } 
  }
}

.ant-picker-content th {
  color: $navy;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-selected) .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-selected) .ant-picker-cell-inner {
  background-color: $cornflower!important;
  border-radius: 2px!important;
}

.ant-picker-cell-in-view {
  color: $navy;

  .ant-picker-cell-range-end, .ant-picker-cell-range-start {
    background-color: $bg !important;
  }
}

.ant-picker-cell {
  border-radius: 8px;
  //background-color: $white!important;
  border: solid 2px $white;

  &:hover {
    background-color: $bg;
    border-radius: 8px;
  }

  &::before {
    background: transparent !important;
  }

  &.ant-picker-cell-selected {
    background-color: $dodger-blue !important;
    .ant-picker-cell-inner {
      color: white !important;
    }
  }

  .ant-picker-cell-inner {
    background: transparent !important;

    &::after {
      background: transparent !important;
    }
  }
}

.ant-picker-cell-disabled {
  opacity: 0.5!important;
}

.ant-picker-cell-range-hover,
.ant-picker-cell-range-hover-start,
.ant-picker-cell-range-hover-end {
  &::before,
  &::after {
    display: none;
  }
}

.react-calendar {
  border: solid 1px $bg !important;
  border-radius: 5px;
  max-width: 310px;

  &__month-view__days__day--weekend {
    color: $navy70;
  }

  &__tile {
    border-radius: 5px;

    &--now {
      background-color: $bg !important;
    }

    &--active {
      background-color: $dodger-blue !important;
      color: white;
    }
  }
}

