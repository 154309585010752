:root {
  --amplify-font-family: 'Open Sans', sans-serif;
  --amplify-primary-color: #4572E2;
  --amplify-primary-shade: #4078b5;
  --amplify-primary-tint: #4078b5;
  // --themeColor: red;
  // --hoverColor: yellow;
  // --amplify-primary-contrast: black;
  // --amplify-background-color: black;
  // --amplify-secondary-color: grey;
}

#amplify-container {
  background-color: $bg !important;

  .intelematics-header {
    display: block !important;
    position: relative;
    top: -275px;
    left: 100px;
  }

  amplify-authenticator {
    margin-left: -275px;
  }

  amplify-sign-in,
  amplify-forgot-password {
    box-shadow: 0 0 16px 0 rgba(28, 47, 94, 0.2);
  }

  amplify-sign-in {
    height: 426px;
  }

  amplify-forgot-password {
    margin-top: -131px;
    height: 295px;
  }
}
