//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿
// GLOBAL LAYOUT
//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: $navy;
}

.main {
  @include size(100%);
  min-width: 768px; //ipad size
  display: flex;
  position: relative;
}

a {
  text-decoration: none;
  color: $dodger-blue;
  cursor: pointer;
}

.none {
  display: none !important;
}

.inline-block {
  display: inline-block;
}

.hidden {
  visibility: hidden;
}

.left {
  float: left;
}

.right {
  float: right;
}

.half {
  width: 50%;
}

.full {
  width: 100%;
}

.text-center {
  text-align: center;
}

hr {
  clear: both;
  margin: 20px 0;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $navy40;

  &.no-margin {
    margin-bottom: 0;
    margin-top: 5px;
  }
}

.close {
  @include absolute(25px, 15px, auto, auto);
  @include size(12px);
  cursor: pointer;
}

.active {
  opacity: 1 !important;
  transition: opacity 0.5s ease !important;

  * {
    display: revert;
  }
}

.active-inline {
  display: inline-block !important;
}

.collapse {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;

  * {
    display: none!important;
  }
}

.np-events {
  pointer-events: none;
}

.clickable {
  cursor: pointer;
}

.cmp-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $navy20;
  border-radius: 10px;
  height: 20px;
  padding: 0px 12px;
  color: $navy;
  font-family: 'OpenSans';
  font-size: 9px;
  text-align: center;
}

.intercom-app iframe {
  max-width: 650px!important;
}

.popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  }
.popup-body {
  position: absolute;
  left: 30%;
  right: 30%;
  bottom: 30%;
  top: 30%;
  text-align: center;
  margin: auto;
  border-radius: 15px;
  border-color: black;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.popup-content {
  padding: 20px 20px;
}