//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿
// NAV PANEL
//⦿⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦂⦿

.nav-panel {
  @include size(70px, 100vh);
  background-color: $navy;
  float: left;
  text-align: center;
  min-width: 70px;

  .logo {
    margin: 15px 0;
    width: 35px;
  }

  &__container {
    ul {
      list-style: none;
      padding-left: 0;
    }
  }

  &__item {
    @include size(100%, 70px);
    font-family: 'Open Sans Semibold';
    padding-top: 42px;
    cursor: pointer;
    line-height: 12px;
    font-size: 10px;
    color: $white;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: top 10px center;
    background-size: 30px;
    color: $navy40;

    &:hover {
      color: white;
    }

    &.Volume {
      background: url('../../assets/images/icn-menu-volume.svg') no-repeat top 10px center;
      background-size: 32px;
    }

    &.Speed {
      background: url('../../assets/images/icn-menu-flow.svg') no-repeat top 10px center;
      background-size: 32px;
    }

    &.od {
      background: url('../../assets/images/icn-menu-od.svg') no-repeat top 10px center;
      background-size: 32px;
    }

    &.turning {
      background: url('../../assets/images/icn-menu-intersections.svg') no-repeat top 10px center;
      background-size: 28px;
    }

    &.faq {
      background: url('../../assets/images/icn-faq.svg') no-repeat top 10px center;
    }

    &.feedback {
      background: url('../../assets/images/icn-feedback.svg') no-repeat top 10px center;
    }

    &.logout {
      background: url('../../assets/images/icn-menu-log-out.svg') no-repeat top 10px center;
    }

    &.cart {
      background: url('../../assets/images/icn-cart.svg') no-repeat top 10px center;

      &.not-empty {
        &::after {
          @include absolute(15px, 22px, auto, auto);
          @include size(6px);
          content: '';
          background: $red;
          border-radius: 100%;
        }
      }
    }

    &.active {
      color: white;
      background-color: rgba(255, 65, 129, 0.37);

      &.Volume {
        background-image: url('../../assets/images/icn-menu-volume-selected.svg');
      }
  
      &.Speed {
        background-image: url('../../assets/images/icn-menu-flow-selected.svg');
      }
  
      &.od {
        background-image: url('../../assets/images/icn-menu-od-selected.svg');
      }

      &.turning {
        background-image: url('../../assets/images/icn-menu-intersections-selected.svg');
      }

      &.cart {
        background: url('../../assets/images/icn-cart_active.svg') no-repeat top 10px center;
      }
    }
  }

  &__bottom {
    @include absolute(auto, auto, 0, 0);
    width: 70px;
  }

  .menu-item {
    color: #0d6efd;
    background-color: #E0E7F4!important;
    height: 55px;
    padding: 15px 0px;
    cursor: pointer;
    font-weight: bold;
    font-size: large;
  }
}
